html {
  box-sizing: border-box;
}

body {
  margin: 0;
}

input {
  border: none;
  padding: 0;
}

button {
  border: none;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl,
dd {
  margin: 0;
}

figure {
  margin: 0;
}

img,
video {
  height: auto;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: unset;
  margin: 0;
}
