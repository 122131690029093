$saffron-yellow: #f3c343;
$cola-black: #3c2b00;
$harvest-brown: #906c0a;

$archivo-wide: "Archivo Expanded";
$archivo-condensed: "Archivo ExtraCondensed";
$forever: "NN Forever Grotesk Normal";
$ft88: "FT88";
$editorial: "PP Editorial New";
$handwriting: "Handwriting";

$weight-ultralight: 200;
$weight-regular: 400;
$weight-medium: 500;
