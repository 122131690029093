html {
  background-color: $saffron-yellow;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: $cola-black;
}

a {
  text-decoration: none;
  color: inherit;
}
