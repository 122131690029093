@keyframes smile {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.intro {
  padding-top: 4vw;
  display: grid;
  grid-template-areas:
    ". . . a a a"
    "b c c c c c"
    "d d d e e e"
    "f f f f f f"
    "g g g h i ."
    "j j j j k k"
    "l l l l l l"
    "o o n n n ."
    ". p p p p .";
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: min-content;
  user-select: none;
}

.intro-a {
  grid-area: a;
  font-size: 3vw;
  font-family: $ft88;
  text-transform: uppercase;
  width: 27vw;
}

.intro-b {
  grid-area: b;
  font-family: $archivo-condensed;
}

.intro-b-text {
  transform: rotate(-90deg);
  font-size: 3vw;
  text-transform: uppercase;
  color: $harvest-brown;
  margin-right: -7vw;
}

.intro-c {
  grid-area: c;
  font-family: $editorial;
  font-size: 13vw;
  margin-bottom: -3vw;
}

.intro-d {
  grid-area: d;
  font-size: 3vw;
  font-family: $ft88;
  text-transform: uppercase;
  text-align: right;
  align-self: center;
}

.intro-e {
  grid-area: e;
  font-size: 3vw;
  font-family: $archivo-condensed;
  text-transform: uppercase;
  color: $harvest-brown;
  align-self: center;
  padding-left: 2vw;
}

.intro-f {
  grid-area: f;
  font-size: 12vw;
  font-family: $forever;
  font-feature-settings: 'ss02' on, 'salt' on;
  margin-top: -1.5vw;
  color: transparent;
  -webkit-text-stroke-width: 0.2vw;
  -webkit-text-stroke-color: $cola-black;
  margin-bottom: -4vw;
}

.intro-g {
  grid-area: g;
  font-size: 9vw;
  font-family: $editorial;
  text-transform: uppercase;
  text-align: right;
  align-self: end;
}

.intro-g-sharp {
  font-weight: $weight-ultralight;
}

.intro-h {
  grid-area: h;
  align-self: center;
  animation: smile 10s linear infinite;
  animation-direction: normal;
}

.intro-i {
  grid-area: i;
  font-size: 11vw;
  font-family: $forever;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 0.2vw;
  -webkit-text-stroke-color: $cola-black;
}

.intro-j {
  grid-area: j;
  font-size: 11vw;
  font-family: $forever;
  padding-left: 2vw;
}

.intro-k {
  grid-area: k;
  font-size: 3vw;
  font-family: $archivo-condensed;
  text-transform: uppercase;
  color: $harvest-brown;
  align-self: center;
  padding-left: 4vw;
}

.intro-l {
  grid-area: l;
  text-transform: uppercase;
  font-family: $forever;
  font-size: 8vw;
  justify-self: center;
}

.intro-l-gram {
  font-family: $archivo-wide;
}

.intro-n {
  grid-area: n;
  font-size: 11vw;
  font-family: $forever;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 0.2vw;
  -webkit-text-stroke-color: $cola-black;
  font-feature-settings: 'ss02' on, 'salt' on;
  margin-top: -3vw;
  margin-bottom: -2.5vw;
}

.intro-o {
  grid-area: o;
  font-size: 2vw;
  font-family: $archivo-condensed;
  font-feature-settings: 'ss02' on, 'salt' on;
  align-self: center;
  display: grid;
  line-height: 1.1;
  text-transform: uppercase;
  color: $harvest-brown;
  justify-self: end;
}

.intro-p {
  grid-area: p;
  text-transform: uppercase;
  font-family: $editorial;
  font-size: 9vw;
}
