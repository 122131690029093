@import "reset.scss";
@import "breakpoints.scss";
@import "variables.scss";
@import "fonts.scss";
@import "defaults.scss";
@import "intro.scss";
@import "list.scss";
@import "video.scss";

@keyframes pageload {
  0% {
    opacity: 0;
    transform: scale(0.99);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

body {
  overflow: hidden;
}

.main-grid {
  display: grid;
  padding: 4vw;
  padding-top: 0;
  padding-bottom: 0;
  animation-name: pageload;
  animation-duration: 0.6s;
  transform-origin: bottom center;

  @include respond-above(large) {
    grid-template-columns: 2fr 1fr;
    padding-left: 4vw;
    padding-right: 6vw;
    padding-bottom: unset;

  }
}
