html {
  box-sizing: border-box;
}

body {
  margin: 0;
}

input, button {
  border: none;
  padding: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

dl, dd, figure {
  margin: 0;
}

img, video {
  width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6, p {
  font-weight: unset;
  margin: 0;
}

@font-face {
  font-family: Archivo Expanded;
  src: url("ArchivoExpanded-Regular.76c4b832.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Archivo Expanded;
  src: url("ArchivoExtraCondensed-Medium.bcef6fd3.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: NN Forever Grotesk Normal;
  src: url("NNForeverGrotesk-Normal.5b76ac1f.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: FT88;
  src: url("FT88-Regular.7818930c.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Archivo ExtraCondensed;
  src: url("ArchivoExtraCondensed-Regular.afdc00de.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PP Editorial New;
  src: url("PPEditorialNew-Ultralight.edb81d33.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: PP Editorial New;
  src: url("PPEditorialNew-Regular.887b3baf.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Handwriting;
  src: url("Handwriting.5ab67a93.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html {
  background-color: #f3c343;
}

h1, h2, h3, h4, h5, h6, p {
  color: #3c2b00;
}

a {
  color: inherit;
  text-decoration: none;
}

@keyframes smile {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.intro {
  -webkit-user-select: none;
  user-select: none;
  grid-template-rows: min-content;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: ". . . a a a"
                       "b c c c c c"
                       "d d d e e e"
                       "f f f f f f"
                       "g g g h i ."
                       "j j j j k k"
                       "l l l l l l"
                       "o o n n n ."
                       ". p p p p .";
  padding-top: 4vw;
  display: grid;
}

.intro-a {
  text-transform: uppercase;
  grid-area: a;
  width: 27vw;
  font-family: FT88;
  font-size: 3vw;
}

.intro-b {
  grid-area: b;
  font-family: Archivo ExtraCondensed;
}

.intro-b-text {
  text-transform: uppercase;
  color: #906c0a;
  margin-right: -7vw;
  font-size: 3vw;
  transform: rotate(-90deg);
}

.intro-c {
  grid-area: c;
  margin-bottom: -3vw;
  font-family: PP Editorial New;
  font-size: 13vw;
}

.intro-d {
  text-transform: uppercase;
  text-align: right;
  grid-area: d;
  align-self: center;
  font-family: FT88;
  font-size: 3vw;
}

.intro-e {
  text-transform: uppercase;
  color: #906c0a;
  grid-area: e;
  align-self: center;
  padding-left: 2vw;
  font-family: Archivo ExtraCondensed;
  font-size: 3vw;
}

.intro-f {
  font-feature-settings: "ss02" on, "salt" on;
  color: #0000;
  -webkit-text-stroke-width: .2vw;
  -webkit-text-stroke-color: #3c2b00;
  grid-area: f;
  margin-top: -1.5vw;
  margin-bottom: -4vw;
  font-family: NN Forever Grotesk Normal;
  font-size: 12vw;
}

.intro-g {
  text-transform: uppercase;
  text-align: right;
  grid-area: g;
  align-self: end;
  font-family: PP Editorial New;
  font-size: 9vw;
}

.intro-g-sharp {
  font-weight: 200;
}

.intro-h {
  grid-area: h;
  align-self: center;
  animation: 10s linear infinite smile;
}

.intro-i {
  text-transform: uppercase;
  color: #0000;
  -webkit-text-stroke-width: .2vw;
  -webkit-text-stroke-color: #3c2b00;
  grid-area: i;
  font-family: NN Forever Grotesk Normal;
  font-size: 11vw;
}

.intro-j {
  grid-area: j;
  padding-left: 2vw;
  font-family: NN Forever Grotesk Normal;
  font-size: 11vw;
}

.intro-k {
  text-transform: uppercase;
  color: #906c0a;
  grid-area: k;
  align-self: center;
  padding-left: 4vw;
  font-family: Archivo ExtraCondensed;
  font-size: 3vw;
}

.intro-l {
  text-transform: uppercase;
  grid-area: l;
  justify-self: center;
  font-family: NN Forever Grotesk Normal;
  font-size: 8vw;
}

.intro-l-gram {
  font-family: Archivo Expanded;
}

.intro-n {
  text-transform: uppercase;
  color: #0000;
  -webkit-text-stroke-width: .2vw;
  -webkit-text-stroke-color: #3c2b00;
  font-feature-settings: "ss02" on, "salt" on;
  grid-area: n;
  margin-top: -3vw;
  margin-bottom: -2.5vw;
  font-family: NN Forever Grotesk Normal;
  font-size: 11vw;
}

.intro-o {
  font-feature-settings: "ss02" on, "salt" on;
  text-transform: uppercase;
  color: #906c0a;
  grid-area: o;
  place-self: center end;
  font-family: Archivo ExtraCondensed;
  font-size: 2vw;
  line-height: 1.1;
  display: grid;
}

.intro-p {
  text-transform: uppercase;
  grid-area: p;
  font-family: PP Editorial New;
  font-size: 9vw;
}

.list {
  -webkit-user-select: none;
  user-select: none;
  padding-top: 4vw;
}

.list-items {
  row-gap: 4vw;
  display: grid;
}

.list-item {
  row-gap: .4vw;
  display: grid;
}

.list-item-description {
  text-align: justify;
  font-feature-settings: "ss02" on, "salt" on;
  margin-top: -.5vw;
  font-family: NN Forever Grotesk Normal;
  font-size: 1.2vw;
  line-height: 1.5;
}

.list-item-detail {
  font-variant-numeric: slashed-zero;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: .6vw;
  font-family: Archivo ExtraCondensed;
  font-size: 1.5vw;
  display: grid;
}

.list-item-detail-logo {
  width: 1.2vw;
}

.list-item-label {
  grid-template-columns: min-content;
  grid-auto-flow: column;
  font-family: Archivo ExtraCondensed;
  font-size: 3vw;
  display: grid;
}

.list-item-label-emphasis-a {
  font-family: PP Editorial New;
  font-size: 2.9vw;
}

.list-item-label-emphasis-b {
  font-family: PP Editorial New;
  font-size: 2.6vw;
  line-height: 1.4;
}

.video {
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  height: 100%;
  transition: all .3s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1.1);
}

@media (width >= 992px) {
  .video {
    left: 35%;
  }
}

.video-done {
  opacity: 1;
  transform: translate(-50%, -50%)scale(1);
}

@keyframes pageload {
  0% {
    opacity: 0;
    transform: scale(.99);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

body {
  overflow: hidden;
}

.main-grid {
  transform-origin: bottom;
  padding: 0 4vw;
  animation-name: pageload;
  animation-duration: .6s;
  display: grid;
}

@media (width >= 992px) {
  .main-grid {
    padding-left: 4vw;
    padding-right: 6vw;
    padding-bottom: unset;
    grid-template-columns: 2fr 1fr;
  }
}

/*# sourceMappingURL=index.fd0a50fb.css.map */
