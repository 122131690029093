@font-face {
  font-family: $archivo-wide;
  src: url("../fonts/ArchivoExpanded-Regular.woff2") format("woff2");
  font-weight: $weight-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $archivo-wide;
  src: url("../fonts/ArchivoExtraCondensed-Medium.woff2") format("woff2");
  font-weight: $weight-medium;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $forever;
  src: url("../fonts/NNForeverGrotesk-Normal.woff2") format("woff2");
  font-weight: $weight-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $ft88;
  src: url("../fonts/FT88-Regular.woff2") format("woff2");
  font-weight: $weight-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $archivo-condensed;
  src: url("../fonts/ArchivoExtraCondensed-Regular.woff2") format("woff2");
  font-weight: $weight-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $editorial;
  src: url("../fonts/PPEditorialNew-Ultralight.woff2") format("woff2");
  font-weight: $weight-ultralight;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $editorial;
  src: url("../fonts/PPEditorialNew-Regular.woff2") format("woff2");
  font-weight: $weight-regular;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $handwriting;
  src: url("../fonts/Handwriting.woff2") format("woff2");
  font-weight: $weight-regular;
  font-style: normal;
  font-display: swap;
}
